<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card-actions
          ref="chartCard"
          no-body
          action-refresh
          :title="$t('Pool.Status.title')"
          @refresh="refreshCard('chartCard')"
        >
          <b-card-body>
            <!-- apex chart -->
            <vue-apex-charts
              type="donut"
              height="300"
              class="my-1"
              :options="chartOptions"
              :series="chartSeries"
            />
            <!-- chart info -->
            <b-row class="justify-content-md-center">
              <b-col
                v-for="(data,index) in chartData.chartInfo"
                :key="data.name"
                xl="2"
                md="4"
                sm="6"
              >
                <statistic-card-vertical
                  :icon="data.icon"
                  :statistic="getStatsForCard(index)"
                  :statistic-title="data.name"
                  :color="data.iconColor"
                />
              </b-col>
            </b-row>
            <!-- Alert, to warn in case of error -->
            <b-alert
              v-model="showAlert"
              v-height-fade.appear
              variant="danger"
              dismissible
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertOctagonIcon"
                />
                <span><strong>{{ $t("Pool.Status.alert.title") }}:</strong> {{ $t("Pool.Status.alert.message") }}</span>
              </div>
            </b-alert>
          </b-card-body>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCardBody, BAlert,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueApexCharts from 'vue-apexcharts'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BAlert,
    VueApexCharts,
    StatisticCardVertical,
    BCardActions,
  },
  directives: {
    heightFade,
    Ripple,
  },
  data() {
    return {
      showAlert: false,
      chartData: {
        chartInfo: [
          {
            icon: 'CheckCircleIcon',
            name: this.$i18n.t('Pool.Status.chart.available'),
            iconColor: 'success',
          },
          {
            icon: 'RefreshCcwIcon',
            name: this.$i18n.t('Pool.Status.chart.transition'),
            iconColor: 'warning',
          },
          {
            icon: 'LoaderIcon',
            name: this.$i18n.t('Pool.Status.chart.busy'),
            iconColor: 'danger',
          },
          {
            icon: 'ClockIcon',
            name: this.$i18n.t('Pool.Status.chart.queued'),
            iconColor: 'info',
          },
        ],
      },
      queueSize: 0,
      chartSeries: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [
          this.$i18n.t('Pool.Status.chart.available'),
          this.$i18n.t('Pool.Status.chart.transition'),
          this.$i18n.t('Pool.Status.chart.busy'),
        ],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  // showAlways: false,
                  label: this.$i18n.t('Pool.Status.chart.total'),
                  color: undefined,
                  formatter(w) {
                    return w.config.series.reduce((a, b) => a + b, 0)
                  },
                },
              },
            },
          },
        },
        legend: { show: false },
        noData: { text: this.$i18n.t('Pool.Status.chart.loading') },
        stroke: { width: 0 },
        colors: [$themeColors.success, $themeColors.warning, $themeColors.danger],
      },
    }
  },
  created() {
    this.getPoolStatus()
  },
  methods: {
    getPoolStatus() {
      // console.log('Calling pool-status')
      this.showAlert = false
      return this.$http.get('/admin/pool-status')
        .then(response => {
          // console.log(response.data.stats)
          // Filter [available] and [busy] arrays to NOT include
          // Workers in [transition]
          const { transition } = response.data.stats // Equals to transition = response.data.stats.transition
          const available = response.data.stats.available.filter(worker => !transition.includes(worker))
          const busy = response.data.stats.busy.filter(worker => !transition.includes(worker))
          this.chartSeries = [
            available.length,
            transition.length,
            busy.length,
          ]
          this.queueSize = response.data.stats.queue_size
        })
        .catch(() => {
          // console.log('There was an error: ', error)
          this.showAlert = true
        })
    },
    getStatsForCard(index) {
      const len = this.chartSeries.length
      if (len > 0) {
        if (len > index) {
          return this.chartSeries[index]
        }
        return this.queueSize
      }
      return 0
    },
    refreshCard(cardName) {
      this.getPoolStatus().finally(() => {
        this.$refs[cardName].showLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
